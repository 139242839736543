import {createI18n} from 'vue-i18n'


import en from './lang/en.json'
import tr from './lang/tr.json'


const messages = {
  en,
  tr
}

const numberFormats = {
  'tr-TR': {
    currency: {
      style: 'currency', currency: 'TRY', notation: 'standard'
    },
    decimal: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
    },
    percent: {
      style: 'percent', useGrouping: false
    }
  }
}

const locale = sessionStorage.getItem("locale");

const i18n = createI18n({
  numberFormats,
  locale:  locale || "tr",
  fallbackLocale: "tr",
  messages: messages
});
export default i18n;
